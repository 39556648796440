import { Mail as MailIcon, Message as MessageIcon, Telegram, WhatsApp } from '@mui/icons-material'
import { Box, Button, Typography, styled } from '@mui/material'
import { MessageIds } from 'components/IntlProvider'
import LanguagePicker from 'components/LanguagePicker'
import PaletteModePicker from 'components/PaletteModePicker'
import RoundedDrawer from 'components/RoundedDrawer'
import { createElement as $, FC, ReactElement, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Container from './Container'

const ChooseMethod = () => {
  const [alternative, setAlternative] = useState(false)
  const navigate = useNavigate()
  return $(Container, null,
    $(Logo, { src: `${process.env.PUBLIC_URL}/logo.svg` }),
    $(Typography, { variant: 'h5', align: 'center', paragraph: true },
      $(FormattedMessage, { id: 'registration.chooseMethod' })),
    $(Typography, { variant: 'body2', align: 'center', paragraph: true },
      $(FormattedMessage, { id: 'registration.quickData' })),
    $(MethodButton, {
      onClick: () => navigate('whatsapp'),
      icon: $(WhatsApp),
      label: 'registration.whatsapp'
    }),
    $(MethodButton, {
      onClick: () => setAlternative(true),
      icon: $(Telegram),
      label: 'registration.telegram'
    }),
    $(MethodButton, {
      onClick: () => setAlternative(true),
      icon: $(MailIcon),
      label: 'registration.vk'
    }),
    $(Box, { height: '1rem' }),
    $(MethodButton, {
      onClick: () => setAlternative(true),
      label: 'registration.alternative'
    }),
    $(Box, { flexGrow: 1 }),
    $(Box, { width: '100%', justifyContent: 'space-between', display: 'flex' },
      $(LanguagePicker),
      $(PaletteModePicker)),
    $(RoundedDrawer, {
      anchor: 'bottom',
      open: alternative,
      onClose: () => setAlternative(false)
      },
      $(Alternative)))
}

const Logo = styled('img')({
  height: '6rem'
})

const MethodButton: FC<MethodButtonProps> = ({
  icon,
  label,
  onClick
}) =>
  $(StyledMethodButton, {
    onClick,
    style: icon && {
      justifyContent: 'flex-start',
      paddingLeft: '1rem',
    },
    fullWidth: true,
    size: 'large',
    startIcon: icon
    },
    $(FormattedMessage, { id: label }))

const StyledMethodButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1]
}))

const Alternative = () => {
  const navigate = useNavigate()
  return $(Box, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1rem',
    gap: '1rem'
    },
    $(MethodButton, {
      onClick: console.log,
      icon: $(MailIcon),
      label: 'registration.mail'
    }),
    $(MethodButton, {
      onClick: () => navigate('sms'),
      icon: $(MessageIcon),
      label: 'registration.sms'
    }))
}

type MethodButtonProps = {
  icon?: ReactElement
  onClick: () => void
  label: MessageIds
}

export default ChooseMethod