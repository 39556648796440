import { ResultProps } from "pages/Search"
import { Dispatch, SetStateAction } from "react"

const url = process.env.REACT_APP_URL
// const token = process.env.REACT_APP_TOKEN
const input_person_number = 2

const handleSearch = async (query: string, setResult: Dispatch<SetStateAction<ResultProps | undefined>>) => {
  try {
    const response = await fetch(url!, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query, input_person_number })
    })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json()
  data && setResult(data)
	} catch (error) {
		console.error('Error:', error)
	}
}

export default handleSearch