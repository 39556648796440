import AddIcon from '@mui/icons-material/Add'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import TryIcon from '@mui/icons-material/Try'
import { Box, Fade, IconButton, Slide, Theme, Typography, styled, useTheme } from '@mui/material'
import { createElement as $, Dispatch, FC, SetStateAction, forwardRef, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation'
import { MessageIds } from './IntlProvider'
import SavingControls from './SavingControls'
import { UserDataProps } from './UserData'

const AISummary: FC<UserDataProps> = (data) => {
  const [visible, setVisible] = useState({
    controls: false,
    related: false
  })

  return $(Box, { height: '100vh' }, 
    $(Content, { data, visible, setVisible }),
    $(SavingControlsBox, null,
      $(Slide, {
        in: visible.controls, 
        direction: 'up',
        children: $(SavingControlsWrapper, { data })
      }
    )))
}

const Content: FC<ContentProps> = ({ data, visible, setVisible }) => {
  const ref = useRef<any>(null)
  const [distance, setDistance] = useState(0)
  const intl = useIntl()
  const theme = useTheme()
  const color = theme.palette.primary.main 

  useEffect(() => {
    const calculateDistance = () => {
      if (ref.current) {
        const blockRect = ref.current.getBoundingClientRect()
        const viewportHeight = window.innerHeight
        const distanceToBottom = viewportHeight - blockRect.bottom
        setDistance(distanceToBottom)
        if (distance < 30) {
          window.scrollBy({ top: 24, left: 0, behavior: 'smooth' })
        }
      }
    }
    calculateDistance()
    const resizeObserver = new ResizeObserver(calculateDistance)
    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [distance])

  return $(Box, { display: 'flex', flexDirection: 'column', padding: '1rem', gap: '.5rem' },
    $(Box, { display: 'flex', gap: '.5rem', alignItems: 'center', sx: { color }}, 
      $(AutoAwesomeIcon),
      $(Typography, { variant: 'h6' }, $(FormattedMessage, { id: 'referral.ai' }))),
    $(Box, { ref, display: 'flex', flexDirection: 'column', gap: '2rem' },
      $(SummaryAnimation, { text: intl.formatMessage({ id: 'referral.ai.summary.mock.chakur' }), setVisible, theme }),
      $(Box, { flexGrow: 1, position: 'relative', minHeight: '4rem' },
        $(Fade, { 
          in: visible.related, 
          timeout: 1500,
          children: $(RelatedQuestionsWrapper)
        })),
      ))
}

const SummaryAnimation: FC<AnimationProps> = ({ text, setVisible, theme }) => {
  const summaryIntro = text.split(',', 2).toString()

  return $(TypeAnimation, { 
    sequence: [
      '', 1500, 
      summaryIntro, 
      () => setVisible(prev => ({ ...prev, controls: true })),
      text,
      () => setVisible(prev => ({ ...prev, related: true })),
    ],
    speed: 75,
    style: { fontSize: 16, color: theme.palette.primary.main, whiteSpace: 'pre-line' }, 
    omitDeletionAnimation: true,
  })
}

const SavingControlsWrapper = forwardRef((props: SummaryProps, ref) => {
  const { data, ...otherProps } = props
  return $(BoxWithBackground, { ...otherProps, ref },
    $(SavingControls, data))
})

const RelatedQuestionsWrapper = forwardRef((props, ref) =>
  $('div', { ...props, ref },
    $(RelatedQuestionsBox, null,
      $(Box, { display: 'flex', gap: '.5rem', alignItems: 'center' }, 
        $(TryIcon),
        $(Typography, { variant: 'h6' }, $(FormattedMessage, { id: 'referral.related' }))),
      relatedQuestionIds.map(RelatedQuestionsIteratee)))
)

const RelatedQuestionsIteratee = (id: MessageIds) => {
  const intl = useIntl()
  const navigate = useNavigate()
  return $(QuestionContainer, null,
    $(Typography, { variant: 'body2' }, intl.formatMessage({ id })), 
    $(IconButton, { onClick: () => navigate('/registration') }, $(AddIcon)))
}

const relatedQuestionIds: MessageIds[] = [
  'referral.related.mock.chakur1',
  'referral.related.mock.chakur2',
  'referral.related.mock.chakur3'
]

const RelatedQuestionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', 
  gap: '.5rem', 
  padding: '1rem',
  paddingRight: '6px',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '8rem',
  borderBlock: `1px solid ${theme.palette.divider}`
}))

const QuestionContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textWrap: 'wrap',
})

const SavingControlsBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: '150px',
  display: 'flex',
  alignItems: 'end',
}))

const BoxWithBackground = styled(Box)(({ theme }) => ({
  as: 'div',
  width: '100%',
  padding: '1rem',
  paddingTop: '2rem',
  background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${theme.palette.background.default} 20%)`,
}))

type SummaryProps = {
  data: UserDataProps
}

type ContentProps = {
  data: UserDataProps
  visible: { controls: boolean, related: boolean }
  setVisible: AnimationProps['setVisible']
}

type AnimationProps = {
  text: string
  setVisible: Dispatch<SetStateAction<{ controls: boolean; related: boolean }>>
  theme: Theme
}

export default AISummary