import { Card, CardProps, Typography, styled } from "@mui/material"
import { createElement as $ } from "react"
import EqualizerIcon from '@mui/icons-material/Equalizer';

const RatingBadge = (props: CardProps, data: any) =>
  $(CardWrapper, { ...props }, 
    $(EqualizerIcon, { sx: { fontSize: 14, transform: 'scale(1, 0.8)' }}), 
    $(Typography, { variant: 'h6', sx: { fontSize: 10 }}, 
      (Math.random() * 100).toFixed(2).toString().replace('.', ',')))

const CardWrapper = styled(Card)(({ theme }) => ({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  gap: '2px',
  height: '1rem',
  borderRadius: theme.shape.borderRadius - 5, 
  width: '50px',
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`
}))

export default RatingBadge