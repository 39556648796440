import PeopleIcon from '@mui/icons-material/People';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Button, Divider, Paper, Typography, styled } from "@mui/material";
import { createElement as $ } from "react";
import { FormattedMessage } from "react-intl";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const StatsCard = () => {
  return $(Stats, null,
    $(Box, { display: 'flex', justifyContent: 'space-between', width: '100%' },
      $(Label, { sx: { backgroundColor: 'rgba(50, 101, 255, 0.1)' }},
        $(Box, { display: 'flex', gap: '.25rem' },
          $(PeopleIcon, { fontSize: 'small' }),
          $(Typography, { variant: 'caption' }, $(FormattedMessage, { id: 'main.stats.all'}))),
        $(Typography, { variant: 'h6' }, '1')),
      $(Box, { width: '1rem' }),
      $(Label, { sx: { background: 'rgba(255, 227, 189, 0.6)' }},
        $(Box, { display: 'flex', gap: '.25rem' },
          $(VerifiedIcon, { fontSize: 'small' }),
          $(Typography, { variant: 'caption' }, $(FormattedMessage, { id: 'main.stats.starred'}))),
        $(Typography, { variant: 'h6' }, '1'))),
    $(Box, { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '.5rem', width: '100%' },
      $(Typography, { variant: 'caption' }, $(FormattedMessage, { id: 'main.stats.month' })),
      $(Box, { display: 'flex', gap: '1rem', width: '100%' },
        $(Box, { display: 'flex', flexDirection: 'column', alignItems: 'end', width: '50%' },
          $(Box, { display: 'flex'  }, 
            $(Typography, { variant: 'h6' }, '+1'),
            $(Typography, { variant: 'h6' }, '/'),
            $(Typography, { variant: 'h6', color: 'orange' }, '+1')),
          $(Typography, { variant: 'caption' }, $(FormattedMessage, { id: 'main.stats.contacts' }))),
        $(Divider, { orientation: 'vertical', flexItem: true }),
        $(Box, { display: 'flex', flexDirection: 'column', width: '50%' },
          $(Typography, { variant: 'h6' }, $(FormattedMessage, { id: 'main.stats.rating', values: { rating: 2 }})),
          $(Typography, { variant: 'caption' }, $(FormattedMessage, { id: 'main.stats.network.tempo' }))))),
    $(Label, { sx: { backgroundColor: 'rgba(50, 101, 255, 0.1)', width: '100%' }},
      $(Box, { display: 'flex', gap: '.5rem'}, 
        $(TrendingUpIcon),
        $(FormattedMessage, { id: 'main.stats.network.statistics' })))
  )
}

const Stats = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  gap: '1rem',
  padding: '1rem',
  backgroundColor: theme.palette.background.paper
}))

const Label = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '50%',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  padding: '.5rem 1rem', 
  color: theme.palette.text.primary
}))

export default StatsCard