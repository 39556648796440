import { Box, Skeleton, styled } from '@mui/material'
import AISummary from 'components/AISummary'
import ContactsContainer from 'components/TopContacts'
import UserData from 'components/UserData'
import { chakurData } from 'pages/Qr'
import { createElement as $, Fragment, useState } from 'react'

const ReferralWithMocks = () => {
  const [visible, setVisible] = useState(false)

  return $(Fragment, null,
    $(Box, {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      },
      $(Box, {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: '1rem'
        },
        $(AvatarContainer, null, 
          !visible && $(LoadingAvatar),
          $(Avatar, { 
            style: visible ? {} : { display: 'none' },
            width: '100%', 
            height: '100%',
            sx: { objectFit: 'cover' },
            src: chakurData.photo, 
            onLoad: () => setVisible(true)
          })),
          $(UserData, chakurData))),
    $(ContactsContainer, chakurData),
    $(AISummary, chakurData)
  )
}

const AvatarContainer = styled(Box)({
  minWidth: '140px',
  height: '180px',
})

const Avatar = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius
}))

const LoadingAvatar = () => 
  $(Skeleton, {
    variant: 'rounded',
    width: '100%',
    height: '100%',
    sx: { 
      aspectRatio: 1/1,
      objectFit: 'cover'
    } 
  })

export default ReferralWithMocks