import { fakerRU } from '@faker-js/faker'
import PeopleIcon from '@mui/icons-material/People'
import VerifiedIcon from '@mui/icons-material/Verified'
import { Box, Typography, styled } from '@mui/material'
import { createElement as $, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import RatingBadge from './RatingBadge'

const ContactsContainer: FC<UserDataProps> = (data) => {
  return $(StyledWrapper, null, 
    $(Box, { display: 'flex', gap: '.5rem', padding: '1rem', pb: 0, alignItems: 'center' }, 
      $(PeopleIcon, { sx: { mb: '2px' }}),
      $(Typography, { variant: 'h6' }, $(FormattedMessage, { id: 'referral.topContacts', values: { name: data.firstName } }))),
    $(Box, { 
      display: 'flex', 
      gap: '1rem',
      sx: { 
        overflow: 'scroll',
        '&::-webkit-scrollbar': { display: 'none' }
      }}, 
      chakurMockContacts.map(ContactIteratee)))
}

const ContactIteratee: FC<UserDataProps> = (data) => {
  // const getPreparedName = (name: string) => name.at(0) + '.'.repeat(5) + name.at(-1)
  // const preparedName = `${getPreparedName(data.firstName)} ${getPreparedName(data.lastName)}`
  const unveiledName = `${data.firstName} ${data.lastName}`

  return $(Box, { display: 'flex', margin: '1rem', key: data.website},
    $(AvatarWithBadge, { photo: data.photo }),
    $(Box, { display: 'flex', flexDirection: 'column'},
      $(Box, { display: 'flex', alignItems: 'center', gap: '.25rem' },
        $(Typography, { variant: 'h6', sx: { fontSize: '14px', lineHeight: 1.2}}, 
          // preparedName),
          unveiledName),
        $(VerifiedIcon, { sx: { fontSize: 16 }, color: 'warning' })),
      $(Typography, { variant: 'caption', sx: { lineHeight: 1.2, pt: '.25rem' } }, `${data.company}`),
      $(Typography, { variant: 'caption', sx: { opacity: '.5', lineHeight: 1.2} }, `${data.title}`)))
}

const AvatarWithBadge: FC<Pick<UserDataProps, 'photo'>> = ({ photo }) => {
  return $(AvatarContainer, null, 
  $(AvatarOverflowWrapper, null,
    $(Avatar, { src: photo })),
  $(RatingBadge, { sx: { position: 'absolute', bottom: -10 }}))
}

// const createFakeUser = () => {
//   return {
//     firstName: fakerRU.person.firstName(),
//     lastName: fakerRU.person.lastName(),
//     photo: fakerRU.image.avatar(),
//     company: fakerRU.company.name(),
//     title: fakerRU.person.jobTitle(),
//     website: fakerRU.internet.url(),
//     email: fakerRU.internet.email(),
//     telegram: '@' + fakerRU.internet.userName(),
//     whatsapp: fakerRU.phone.number(),
//     instagram: '@' + fakerRU.internet.userName()
//   }
// }

// const mockUsers: UserDataProps[] = faker.helpers.multiple(createFakeUser, { count: 5 })

const StyledWrapper = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.mode === 'light' ? 'rgb(246 246 254)' : theme.palette.background.paper // FIXME apply Theme
}))

const AvatarContainer = styled(Box)({
  position: 'relative', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  marginRight: '1rem', 
  minWidth: '50px',
  height: '40px' 
})

const AvatarOverflowWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden', 
  width: '40px', 
  height: '40px',
  borderRadius: theme.shape.borderRadius
}))

const Avatar = styled('img')(({ theme }) => ({
  width: '40px',
  aspectRatio: '1/1',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  // filter: 'blur(2px)'
}))

const chakurMockContacts = [
  {
    firstName: 'Оскар',
    lastName: 'Хартманн',
    photo: 'image2.png',
    company: 'Carprice, CarFix, KupiVIP, Aktivo',
    title: 'Инвестор, основатель',
    website: fakerRU.internet.url(),
    email: '',
    telegram: '',
    whatsapp: '',
    instagram: ''
  },
  {
    firstName: 'Семён',
    lastName: 'Теняев',
    photo: 'image1.jpg',
    company: 'Социальная сеть Tenchat, ВБЦ',
    title: 'Основатель',
    website: fakerRU.internet.url(),
    email: '',
    telegram: '',
    whatsapp: '',
    instagram: ''
  },
  {
    firstName: 'Иван',
    lastName: 'Юнин',
    photo: 'image3.png',
    company: 'Инвестиционный фонд ТИМ',
    title: 'Генеральный директор',
    website: fakerRU.internet.url(),
    email: '',
    telegram: '',
    whatsapp: '',
    instagram: ''
  },
]

export type UserDataProps = {
  firstName: string;
  lastName: string;
  photo: string;
  company: string;
  title: string;
  website: string;
  email: string;
  telegram: string;
  whatsapp: string;
  instagram: string;
}

export default ContactsContainer