import { LocaleProvider, Locales } from 'components/IntlProvider'
import { createElement as $, useContext } from 'react'
import { useIntl } from 'react-intl'
import PopoverButtton, { PopoverButtonProps } from './PopoverButton'

const LanguagePicker = () => {
  const { locale } = useIntl()
  const setLocale = useContext(LocaleProvider)
  return $<PopoverButtonProps<Locales>>(PopoverButtton, {
    options,
    value: locale as Locales,
    onChange: setLocale,
    popoverPosition: { vertical: 'bottom', horizontal: 'left' }
  })
}

const localesMap: Record<Locales, { short: string, long: string }> = {
  ru: {
    short: 'ру',
    long: 'русский'
  },
  en: {
    short: 'en',
    long: 'English'
  },
  by: {
    short: 'бел',
    long: 'беларуская'
  }
}

const options = Object.entries(localesMap).map(([key, { short, long }]) => {
  const value = key as Locales
  return {
    key: value,
    value: value,
    label: long,
    icon: $('div', null, short)
  }
})


export default LanguagePicker