import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HandshakeIcon from '@mui/icons-material/Handshake'
import { Box, Paper, Typography, styled } from '@mui/material'
import { createElement as $, FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { SearchResultProps } from '.'


const PersonCard: FC<SearchResultProps> = (data) => {
  const [expanded, setExpanded] = useState(false)
  const [expandedPath, setExpandedPath] = useState(false)
  const intl = useIntl()
  const icon = expanded ? $(ExpandLessIcon) : $(ExpandMoreIcon)
  const person = expanded ? data.person : shortenText(data.person)
  const message = intl.formatMessage({ id: expanded ? 'search.collapse' : 'search.expand' })
  const path = expandedPath ? data.path : data.path.slice(0, 2)

  return $(Person, { key: data.path.toString() + data.similarity },
    $(PathHolder, null, 
      path.map(PathPerson),
      !expandedPath && data.path.length > 2 && 
        $(Box, { onClick: () => setExpandedPath(true) }, 
          $(MoreFromPath, data.path))),
    person, 
    $(Typography, { 
      variant: 'subtitle2',
      sx: { 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        opacity: '.5'
      },
      onClick: () => setExpanded(!expanded) 
      }, 
      icon, message))
}

const PathPerson = (name: string) => 
  $(PathPersonHolder, { key: name },
    $(HandshakeIcon, { sx: { fontSize: 'small' }}),
    name.split('. ')[1])

const MoreFromPath = (path: string[]) =>
  $(PathPersonHolder, null, 
    $(HandshakeIcon, { sx: { fontSize: 'small' }}), 
    `+${Object.entries(path).length - 2}`)

const shortenText = (text: string) => {
  const pattern = /^(.*?[.!?]\s){2}/
  const shorten = text.match(pattern)

  return shorten ? `${shorten[0].slice(0, shorten[0].length - 2)}...` : ''
}

const PathHolder = styled(Box)({
  display: 'flex', 
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'wrap',
  columnGap: '.5rem',
  opacity: '.5',
})

const Person = styled(Paper)({
  display: 'flex', 
  flexDirection: 'column', 
  gap: '.5rem',
  padding: '1rem'
})

const PathPersonHolder = styled(Box)({
  display: 'flex', 
  alignItems: 'center', 
  gap: '.5rem',
  textWrap: 'nowrap',
  fontSize: 'small'
})

export default PersonCard