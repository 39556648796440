import { Button, MenuItem, Popover, PopoverOrigin, styled } from '@mui/material'
import { createElement as $, FC, Fragment, MouseEventHandler, ReactNode, useState } from 'react'

const PopoverButtton = <V extends string>({
  options,
  onChange,
  value,
  popoverPosition = { vertical: 'bottom', horizontal: 'right' },
}: PopoverButtonProps<V>): ReturnType<FC> => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const onOptionClick = (value: V): MouseEventHandler<HTMLLIElement> => () => {
    onChange(value)
    setAnchorEl(null)
  }
  
  return $(Fragment, null,
    $(Popover, {
      anchorEl,
      open: !!anchorEl,
      onClose: () => setAnchorEl(null),
      anchorOrigin: popoverPosition
      },
      options.map((option) =>
        $(StyledMenuItem, {
          key: option.value,
          onClick: onOptionClick(option.value)
          },
          option.label,
          option.icon))),
    $(Button, {
      variant: 'outlined',
      size: 'small',
      onClick: (event) => setAnchorEl(event.currentTarget)
      },
      options.find((option) => option.value === value)?.icon ))
}

export type PopoverButtonProps<V extends string> = {
  options: PopoverButonOption<V>[]
  onChange: (value: V) => void
  popoverPosition?: PopoverOrigin
  value: V
}

type PopoverButonOption<V> = {
  value: V
  label: ReactNode
  icon: ReactNode
}

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '1rem',
  minHeight: '40px'
})

export default PopoverButtton
