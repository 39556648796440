import { Box, Link, Typography } from '@mui/material'
import { createElement as $ } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'

const Back = () =>
  $(Box, null,
    $(Typography, { variant: 'caption', paragraph: true, align: 'center', color: 'text.secondary' },
      $(FormattedMessage, { id: 'registration.problem' })),
    $(Typography, { variant: 'caption', paragraph: true, align: 'center' },
      // @ts-ignore
      $(Link, { component: RouterLink, to: '/registration' },
        $(FormattedMessage, { id: 'registration.alternative.full' }))))

export default Back