import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, InputAdornment, Skeleton, TextField, styled } from '@mui/material'
import handleSearch from 'queries/handleSearch'
import { createElement as $, Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import PersonCard from './PersonCard'

const Search = () => {
	const [value, setValue] = useState('')
  const [recentSearch, setRecentSearch] = useState('')
  const [result, setResult] = useState<ResultProps | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const disabled = value === '' || value === recentSearch || loading
  const intl = useIntl()

  const handleSend = () => {
    if (!disabled) {
      handleSearch(value, setResult)
      setRecentSearch(value)
      setLoading(true)
    }
  }

  useEffect(() => {
    result && setLoading(false)
  }, [result])
 
	return $(Container, null,
    $(TextField, {
      value,
      variant: 'outlined',
      fullWidth: true,
      label: intl.formatMessage({ id: 'search.label' }),
      onChange: (event) => setValue(event.target.value),
      onKeyDown: (event) => event.code === 'Enter' && handleSend(),
      InputProps: { 
        endAdornment: 
          $(InputAdornment, { position: 'end' }, 
            !loading && $(IconButton, { 
              onClick: handleSend,
              disabled
              }, 
              $(SearchIcon)))}
    }),
    loading 
      ? $(LoadingList)
      : $(SearchResult, result)
	)
}

const SearchResult = ({ data }: ResultProps) => 
  $(Box, { 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '1rem',
    }, 
    data && data.map(PersonCard))

const LoadingList = () => 
  $(Fragment, null, 
    Array(10).fill('').map((_, i) => 
      $(Skeleton, { key: i, height: '100px', variant: 'rounded' })))

const Container = styled(Box)({
  display: 'flex',
  padding: '1rem',
  flexDirection: 'column',
  gap: '1rem'
})

export type ResultProps = {
  data: SearchResultProps[]
}

export type SearchResultProps = {
  path: string[]
  person: string
  similarity: number
}

export default Search