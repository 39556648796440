import { Box, Dialog, useTheme } from '@mui/material'
import UserData, { UserDataProps } from 'components/UserData'
import qrcode from 'qrcode'
import { createElement as $, FC, Fragment, useEffect, useRef, useState } from 'react'

const Qr = () => {
  // const data: UserDataProps = useMemo(() => ({
  //   firstName: fakerRU.person.firstName(),
  //   lastName: fakerRU.person.lastName(),
  //   photo: fakerRU.image.avatar(),
  //   company: fakerRU.company.name(),
  //   title: fakerRU.person.jobTitle(),
  //   website: fakerRU.internet.url(),
  //   email: fakerRU.internet.email(),
  //   telegram: '@' + fakerRU.internet.userName(),
  //   whatsapp: fakerRU.phone.number(),
  //   instagram: '@' + fakerRU.internet.userName(),
  // }), [])

  const [open, setOpen] = useState(true)

  return $(Fragment, null,
    $(Dialog, { open, onClose: () => setOpen(false) },
      $(Box, {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1rem',
        gap: '1rem',
        maxWidth: '316px'
        },
        $(Canvas, chakurData),
        $(Box, { width: '100%', display: 'flex', alignItems: 'start' },
          $(UserData, chakurData)))))
}

const Canvas: FC<UserDataProps> = (data) => {
  const params = new URLSearchParams(data)
  const url = `${window.location.protocol}//${window.location.hostname}/referral?${params.toString()}`
  const theme = useTheme()
  const ref = useRef()
  useEffect(() => {
    qrcode.toCanvas(ref.current, url, {
    margin: 0,
    scale: 3,
    color: {
      dark: theme.palette.primary.main,
      light: '#FF000000'
    }
  }) }, [url, theme.palette.primary.main, theme.palette.background.paper])

  return $('canvas', { ref })
}

export const chakurData: UserDataProps = {
  firstName: 'Полина' || '',
  lastName: 'Чакур' || '',
  photo: '/image4.jpg' || '',
  company: 'TaxRisk' || '',
  title: 'CO-founder' || '',
  website: 'taxrisk.ru' || '',
  email: '',
  telegram: '',
  whatsapp: '',
  instagram: ''
} 

export default Qr