import { Box, styled } from '@mui/material'

const Container = styled(Box)({
  padding: '1rem',
  paddingTop: '6rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '.5rem',
  minHeight: '100vh'
})

export default Container