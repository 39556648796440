import EmailIcon from '@mui/icons-material/Email'
import InstagramIcon from '@mui/icons-material/Instagram'
import TelegramIcon from '@mui/icons-material/Telegram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Box, IconButton, Link, Typography, styled } from '@mui/material'
import { createElement as $, FC } from 'react'
import RatingBadge from './RatingBadge'

const UserData: FC<UserDataProps> = (data) => {
  const preparedUrl = data.website.replace(/^https?:\/\//, '')

  return $(Container, null,
    $(RatingBadge),
    $(Box, {
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center'
      }, 
      $(Typography, { variant: 'h6', sx: { lineHeight: 1.2 }}, `${data.firstName} ${data.lastName}`),
      $(Typography, { variant: 'body2' }, `${data.company}`),
      $(Typography, { variant: 'caption', sx: { opacity: .5, lineHeight: 1.2 } }, `${data.title}`),
      // $(Typography, { variant: 'h6', sx: { lineHeight: 1.2 }}, `Олег Торбосов`),
      // $(Typography, { variant: 'body2' }, `Company`),
      // $(Typography, { variant: 'caption', sx: { opacity: .5, lineHeight: 1.2 } }, `Company Title`),
      $(Typography, { variant: 'caption' },
        $(Link, { href: data.website }, preparedUrl))),
    $(Box, { display: 'flex', gap: '1rem', justifyContent: 'space-between', width: '100%' },
      $(SocialIcons, { onClick: console.log },
        $(EmailIcon)),
      $(SocialIcons, { onClick: console.log },
        $(TelegramIcon)),
      $(SocialIcons, { onClick: console.log },
        $(InstagramIcon)),
      $(SocialIcons, { onClick: console.log },
        $(WhatsAppIcon))))
}

const Container = styled(Box)({
  display: 'flex', 
  alignItems: 'start', 
  flexDirection: 'column', 
  justifyContent: 'space-between', 
  gap: '.5rem',
  width: '100%'
})

const SocialIcons = styled(IconButton)({
  padding: 0
})

export type UserDataProps = {
  firstName: string;
  lastName: string;
  photo: string;
  company: string;
  title: string;
  website: string;
  email: string;
  telegram: string;
  whatsapp: string;
  instagram: string;
}

export default UserData