import ru from 'lang/ru.json'
import by from 'lang/by.json'
import en from 'lang/en.json'
import { createElement as $, FC, PropsWithChildren, createContext, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

export const locales = { ru, by, en }

export const LocaleProvider = createContext<(locale: Locales) => void>(() => {})

const IntlProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [locale, setLocale] = useState<Locales>('ru')
  return $(LocaleProvider.Provider, { value: setLocale }, 
    $(ReactIntlProvider, {
      locale,
      defaultLocale: locale,
      messages: locales[locale],
      children
    }))
}

export type MessageIds = keyof typeof ru

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: MessageIds
    }
  }
}

export type Locales = keyof typeof locales

export default IntlProvider