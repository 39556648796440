import { Route, Routes } from 'react-router-dom'
import { createElement as $  } from 'react'
import ChooseMethod from './ChooseMethod'
import Sms from './Sms'
import WhatsApp from './WhatsApp'

const Registration = () =>
  $(Routes, null,
    $(Route, { path: 'whatsapp', element: $(WhatsApp) }),
    $(Route, { path: 'sms', element: $(Sms) }),
    $(Route, { path: '*', element: $(ChooseMethod) }))

export default Registration