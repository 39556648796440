
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Box, Paper, Typography, styled } from "@mui/material";
import { createElement as $ } from "react";
import { FormattedMessage } from "react-intl";

const CardScanner = () => {
  return $(Scanner, null, 
    $(Box, { 
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
      }, 
      $(Typography, { variant: 'h6', sx: { lineHeight: '1' }}, $(FormattedMessage, { id: 'main.scanner.title' })),
      $(Typography, { variant: 'caption' }, $(FormattedMessage, { id: 'main.scanner.description' }))),
    $(QrCodeScannerIcon, { sx: { fontSize: '50px' } }))
}

const Scanner = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  padding: '1rem 1.5rem',
  backgroundColor: theme.palette.primary.main
}))

export default CardScanner