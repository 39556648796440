import { Box, Button, ButtonProps, Paper } from "@mui/material"
import { createElement as $, FC } from "react"
import { FormattedMessage } from "react-intl"
import { Link, LinkProps } from "react-router-dom"
import { UserDataProps } from "./UserData"
import EastIcon from '@mui/icons-material/East';

const SavingControls: FC<UserDataProps> = (data) => {
  return $(Box, { display: 'flex', flexDirection: 'column', gap: '.5rem', width: '100%' }, 
    $<ButtonProps & { component: typeof Link } & LinkProps>(Button, {
      component: Link,
      to: '/registration',
      variant: 'contained',
      fullWidth: true,
      sx: {
        boxShadow: 'none',
        textTransform: 'none', 
        display: 'flex', 
        justifyContent: 'space-between', 
        px: '1rem',
        height: '56px'
      }},
      $(FormattedMessage, { id: 'referral.register' }),
      $(EastIcon, { fontSize: 'small' })),
    $(Paper, { 
      elevation: 0,
      sx: { backgroundColor: 'rgb(246 246 254)' }}, // FIXME apply Theme
      $<ButtonProps & { component:'a', download: string }>(Button, {
        component: 'a',
        href: getVcard(data),
        download: `${data.firstName}_${data.lastName}.vcf`,
        fullWidth: true,
        sx: { 
          textTransform: 'none', 
          display: 'flex', 
          justifyContent: 'space-between', 
          px: '1rem' 
        }},
        $(FormattedMessage, { id: 'referral.addvcard' }),
        $(EastIcon, { fontSize: 'small' }))))
}

const getVcard = (data: UserDataProps) => {
  const vCardContent = "BEGIN:VCARD\n" +
  "VERSION:3.0\n" +
  "N:" + data.lastName + ";" + data.firstName + ";;;\n" +
  "FN:" + data.firstName + " " + data.lastName + "\n" +
  "ORG:" + data.company + "\n" +
  "TITLE:" + data.title + "\n" +
  "URL:" + data.website + "\n" +
  "EMAIL:" + data.email + "\n" +
  "END:VCARD";

// PHOTO;MEDIATYPE=image/jpeg:${data.photo}
// NOTE:Telegram: ${data.telegram}, WhatsApp: ${data.whatsapp}, Instagram: ${data.instagram}

  const vCardDataUrl = 'data:text/vcard;charset=utf-8,' + encodeURIComponent(vCardContent)
  
  return vCardDataUrl  
}

export default SavingControls