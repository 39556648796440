import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import MessageIcon from '@mui/icons-material/Message'
import PersonIcon from '@mui/icons-material/Person'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { createElement as $, ReactElement } from 'react'
// import { useNavigate } from 'react-router-dom'

const Navigation = () => {
  // const navigate = useNavigate()
  return $(BottomNavigation, { sx: { width: '100%' }, onClick: (event) => console.log(event) },
    entries.map(NavigationIteratee),
    // $(BottomNavigationAction, {
    //   icon: $(SmallBadge, { badgeContent: 4, color: 'error' }, $(PersonAddIcon))
    //   }),
    // $(BottomNavigationAction, { icon: $(CalendarMonthIcon) }),
    // $(BottomNavigationAction, { icon: $(MessageIcon) }),
    // $(BottomNavigationAction, { icon: $(PersonIcon) })
    )
}

const NavigationIteratee = ([key, config]: [string, NavigationConfig]) =>
  $(BottomNavigationAction, {
    key,
    icon: config.icon,
  })

const navigation: Record<string, NavigationConfig> = {
  main: {
    path: '/',
    icon: $(PersonAddIcon)
  },
  calendar: {
    path: '/calendar',
    icon: $(CalendarMonthIcon)
  },
  chat: {
    path: '/chat',
    icon: $(MessageIcon)
  },
  personal: {
    path: '/personal',
    icon: $(PersonIcon)
  }
}

type NavigationConfig = {
  path: string
  icon: ReactElement
}

const entries = Object.entries(navigation)

// const SmallBadge = styled(Badge)({
//   height: 'unset'
// })

export default Navigation