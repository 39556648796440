import styled from '@emotion/styled'
import { Box, Button, Typography } from '@mui/material'
import { createElement as $ } from 'react'
import { FormattedMessage } from 'react-intl'
import Container from './Container'
import Back from './Back'
import { useNavigate } from 'react-router-dom'

const WhatsApp = () => {
  const phone = process.env.REACT_APP_WHATSAPP_PHONE || ''
  const navigate = useNavigate()
  return $(Container, null,
    $(Logo, { src: `${process.env.PUBLIC_URL}/whatsapp.svg` }),
    $(Typography, { variant: 'h5', align: 'center', paragraph: true },
      $(FormattedMessage, { id: 'registration.whatsapp.send' })),
    $(Box, { height: '6rem' },
      $(Typography, { variant: 'body2', align: 'center', paragraph: true },
        $(FormattedMessage, { id: 'registration.whatsapp.free' }))),
    $(Button, {
      // @ts-ignore
      component: 'a',
      href: getWhatsAppUrl(phone, 'kukarek'),
      target: '_blank',
      variant: 'contained',
      fullWidth: true,
      onClick: () => navigate('/main')
      },
      $(FormattedMessage, { id: 'send' })),
    $(Box, { height: '1rem' }),
    $(Typography, { variant: 'caption', align: 'center', paragraph: true, color: 'text.secondary' },
      $(FormattedMessage, { id: 'registration.whatsapp.send.full' })),
    $(Box, { flexGrow: 1 }),
    $(Back))
}

const Logo = styled('img')({
  height: '3rem'
})

const getWhatsAppUrl = (phone: string, text: string) =>
  `https://api.whatsapp.com/send?phone=${phone}&text=${text}`

export default WhatsApp