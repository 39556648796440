import PeopleIcon from '@mui/icons-material/People'
import SearchIcon from '@mui/icons-material/SearchOff'
import NotificationIcon from '@mui/icons-material/Notifications'
import ExportContactIcon from '@mui/icons-material/ContactPage'
import ScanIcon from '@mui/icons-material/QrCode'
import { Box, IconButton, Paper } from '@mui/material'
import { createElement as $ } from 'react'

const Controls = () => {
  return $(Paper, {
    square: true,
    sx: {
      padding: '.5rem',
      display: 'flex',
      gap: '.5rem'
      }
    },
    $(IconButton, null,
      $(PeopleIcon, { color: 'primary' })),
    $(IconButton, null,
      $(SearchIcon, { color: 'primary' })),
    $(Box, { flexGrow: 1 }),
    $(IconButton, null,
      $(ExportContactIcon)),
    $(IconButton, null,
      $(ScanIcon)),
    $(IconButton, null,
      $(NotificationIcon)))
}

export default Controls