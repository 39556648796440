import { createElement as $ } from 'react'
import Container from './Container'
import Back from './Back'
import { Box, Button, Typography, styled } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const Sms = () => {
  const phone = process.env.REACT_APP_WHATSAPP_PHONE || ''
  return $(Container, null,
    $(Logo, { src: `${process.env.PUBLIC_URL}/sms.svg` }),
    $(Typography, { variant: 'h5', align: 'center', paragraph: true },
      $(FormattedMessage, { id: 'registration.sms.send' })),
    $(Box, { height: '6rem' }),
    $(Button, {
      // @ts-ignore
      component: 'a',
      href: getSmsUrl(phone, 'kukarek'),
      target: '_blank',
      variant: 'contained',
      fullWidth: true
      },
      $(FormattedMessage, { id: 'send' })),
    $(Box, { height: '1rem' }),
    $(Typography, { variant: 'caption', align: 'center', paragraph: true, color: 'text.secondary' },
      $(FormattedMessage, { id: 'registration.sms.send.full' })),
    $(Typography, { variant: 'caption', align: 'center', paragraph: true, color: 'text.secondary' },
      $(FormattedMessage, { id: 'registration.sms.warning' })),
    $(Box, { flexGrow: 1 }),
    $(Back))
}

const Logo = styled('img')({
  height: '3rem'
})

const getSmsUrl = (phone: string, text: string) =>
  `sms:+${phone}?body=${text}`

export default Sms