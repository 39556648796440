import { Box, Paper, styled } from '@mui/material'
import { createElement as $ } from 'react'
import Controls from './Controls'
import Navigation from './Navigation'
import { FormattedMessage } from 'react-intl'
import ArrowRight from '@mui/icons-material/ArrowForward'
import RoundedDrawer from 'components/RoundedDrawer'
import CardScanner from './CardScanner'
import StatsCard from './StatsCard'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const Main = () => {
  return $(Box, {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    },
    $(Controls),
    $(DownloadApp, { square: true, onClick: console.log },
      $(Box, { 
        display: 'flex', 
        gap: '.25rem', 
        alignItems: 'center' 
        }, 
        $(PhoneIphoneIcon),
        $(FormattedMessage, { id: 'main.downloadApp' })),
      $(ArrowRight, { fontSize: 'small' })),
    $(Box, {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '1rem'
      },
      $(CardScanner),
      $(StatsCard)),
    $(Box, { flexGrow: 1 }),
    $(Box, {
      display: 'sticky',
      bottom: 0,
      },
      $(Navigation)),
    $(RoundedDrawer, {
      anchor: 'bottom',
      }))
}

const DownloadApp = styled(Paper)(({ theme }) => ({
  fontSize: '12px',
  alignItems: 'center',
  padding: '.5rem 1rem',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText
}))

export default Main