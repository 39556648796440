import { createElement as $ } from 'react'
import ThemedApp from './ThemedApp'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Registration from './pages/Registration'
import IntlProvider from 'components/IntlProvider'
import Main from 'pages/Main'
import Qr from 'pages/Qr'
import Referral from 'pages/Referral'
import Search from 'pages/Search'

const App = () => {
  return $(BrowserRouter, null,
    $(ThemedApp, null,
      $(IntlProvider, null,
        $(RoutesApp))))
}

const RoutesApp = () => {
  return $(Routes, null,
    $(Route, { path: '/referral', element: $(Referral) }),
    $(Route, { path: '/qr', element: $(Qr) }),
    $(Route, { path: '/search', element: $(Search) }),
    $(Route, { path: 'main/*', element: $(Main)}),
    $(Route, { path: 'registration/*', element: $(Registration)}),
    $(Route, { path: '', element: $(Navigate, { to: 'registration' })}))
}

export default App