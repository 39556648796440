import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, PaletteMode, ThemeProvider, createTheme, useMediaQuery } from '@mui/material'
import { blue } from '@mui/material/colors'
import { createElement as $, FC, PropsWithChildren, createContext, useMemo, useState } from 'react'

const light = {
  primary: blue,
  background: {
    // default: '#F1F1F6'
    default: 'white',
  }
}

const dark = {
  primary: blue,
  background: {
    default: '#000',
  }
}

const getDesignTokens = (mode: PaletteMode) => ({
  shape: {
    borderRadius: 10
  },
  palette: {
    mode,
    ...(mode === 'light' ? light : dark),
  },
})

export const ColorModePickerContext = createContext<{
  setMode: (mode: PaletteModeSetting) => void,
  mode: PaletteModeSetting
}>({
  mode: 'system',
  setMode: () => {}
})

const ThemedApp: FC<PropsWithChildren> = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const systemMode = prefersDarkMode ? 'dark' : 'light'
  // const systemMode = 'light'
  // const systemMode = 'dark'
  const [mode, setMode] = useState<PaletteMode | 'system'>('system')

  const currentMode: PaletteMode = mode === 'system'
    ? systemMode
    : mode

  const theme = useMemo(() => createTheme(getDesignTokens(currentMode)), [currentMode])
  return $(ThemeProvider, { theme },
    $(CssBaseline),
    $(ColorModePickerContext.Provider, { value: { mode, setMode } },
      children))
}

export type PaletteModeSetting = PaletteMode | 'system'

export default ThemedApp