import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto'
import { ColorModePickerContext, PaletteModeSetting } from 'ThemedApp'
import { createElement as $, FC, ReactElement, useContext } from 'react'
import PopoverButton, { PopoverButtonProps } from './PopoverButton'
import { FormattedMessage } from 'react-intl'

const PaletteModePicker: FC = () => {
  const { mode, setMode } = useContext(ColorModePickerContext)
  return $<PopoverButtonProps<PaletteModeSetting>>(PopoverButton, {
    options,
    value: mode,
    onChange: setMode
  })
}

const modes: Record<PaletteModeSetting, ReactElement> = {
  dark: $(Brightness4Icon, { fontSize: 'small', style: { display: 'block' }}),
  light: $(Brightness7Icon, { fontSize: 'small', style: { display: 'block' }}),
  system: $(BrightnessAutoIcon, { fontSize: 'small', style: { display: 'block' }})
}

const options = Object.keys(modes).map((key) => {
  const value = key as PaletteModeSetting
  return {
    key: value,
    value: value,
    label: $(FormattedMessage,{ id: `palette.${value}` }),
    icon: modes[value]
  }
})

export default PaletteModePicker
